import React from "react";

export const LOGO = (
  <svg xmlns="http://www.w3.org/2000/svg"
    id="logo__img"
    style={{marginRight: "6px", fontSize: "34px"}}
    width="1em" height="1em"
    viewBox="0 0 1080 1080">
    <path id="exologo"
      fill="white" stroke="white" strokeWidth="1"
      d="M 132.00,104.42
           C 140.40,103.40 139.98,104.65 147.00,104.96
             167.07,105.87 187.01,111.34 206.00,117.67
             257.36,134.79 296.34,154.67 344.00,179.26
             344.00,179.26 380.00,199.15 380.00,199.15
             383.30,201.03 395.23,208.24 398.00,208.83
             402.13,209.70 410.78,205.03 415.00,203.42
             415.00,203.42 453.00,190.71 453.00,190.71
             474.76,184.55 497.52,181.48 520.00,179.91
             520.00,179.91 532.00,179.00 532.00,179.00
             532.00,179.00 560.00,179.00 560.00,179.00
             560.00,179.00 601.00,183.58 601.00,183.58
             701.45,199.17 788.06,257.63 841.58,344.00
             869.69,389.37 885.87,439.03 891.17,492.00
             891.17,492.00 893.00,515.00 893.00,515.00
             893.04,537.55 892.91,556.51 889.57,579.00
             889.57,579.00 886.55,597.00 886.55,597.00
             885.89,600.32 884.50,603.73 886.04,607.00
             887.53,610.17 896.99,619.85 899.83,623.00
             899.83,623.00 927.87,655.00 927.87,655.00
             958.50,690.58 990.07,729.70 1014.20,770.00
             1026.13,789.92 1040.96,818.38 1041.00,842.00
             1041.00,842.00 1041.00,848.00 1041.00,848.00
             1040.94,853.20 1040.30,856.08 1038.64,861.00
             1029.59,887.90 1003.67,892.28 979.00,892.00
             967.17,891.86 955.63,888.80 944.00,887.13
             903.02,881.25 854.04,866.09 816.00,849.72
             816.00,849.72 767.00,827.75 767.00,827.75
             767.00,827.75 751.00,819.75 751.00,819.75
             749.40,818.95 744.97,816.50 743.42,816.39
             740.94,816.21 736.21,819.82 734.00,821.19
             734.00,821.19 710.00,835.24 710.00,835.24
             681.00,849.98 657.81,859.57 626.00,867.12
             609.80,870.96 579.36,875.80 563.00,876.04
             563.00,876.04 552.00,876.04 552.00,876.04
             552.00,876.04 542.00,877.00 542.00,877.00
             527.32,877.16 487.07,872.07 472.00,869.00
             400.75,854.47 331.09,814.81 283.28,760.00
             236.71,706.60 207.05,641.35 198.28,571.00
             195.67,550.03 195.76,529.11 196.00,508.00
             196.00,508.00 196.91,498.00 196.91,498.00
             198.43,477.28 202.14,457.10 207.37,437.00
             207.37,437.00 213.00,418.00 213.00,418.00
             214.06,414.82 215.76,411.32 214.39,408.00
             213.21,405.17 203.26,394.18 200.59,391.00
             200.59,391.00 171.42,355.00 171.42,355.00
             150.36,328.00 130.23,300.12 112.19,271.00
             112.19,271.00 93.97,240.00 93.97,240.00
             85.07,222.09 74.03,187.95 74.00,168.00
             74.00,168.00 74.00,159.00 74.00,159.00
             74.24,139.08 84.59,119.95 103.00,111.32
             114.73,105.83 119.98,106.14 132.00,104.42 Z
           M 358.00,231.00
           C 358.00,231.00 340.00,221.31 340.00,221.31
             340.00,221.31 310.00,205.26 310.00,205.26
             273.69,186.52 217.05,159.65 178.00,149.87
             165.78,146.81 156.54,145.05 144.00,143.83
             140.84,143.53 139.42,142.94 136.00,143.18
             129.57,143.64 119.73,144.49 115.72,150.10
             112.49,154.63 112.94,162.61 113.00,168.00
             113.23,187.63 125.07,216.07 134.72,233.00
             152.08,263.47 172.61,291.98 193.63,320.00
             193.63,320.00 218.00,351.00 218.00,351.00
             222.75,356.92 227.99,364.49 234.00,369.00
             239.75,355.66 251.20,337.94 259.71,326.00
             287.96,286.35 317.76,259.82 358.00,233.00
             358.00,233.00 358.00,231.00 358.00,231.00 Z
           M 527.00,228.14
           C 527.00,228.14 506.00,230.17 506.00,230.17
             506.00,230.17 469.00,237.15 469.00,237.15
             406.27,253.68 350.04,289.18 309.45,340.00
             296.68,355.99 285.40,373.70 276.25,392.00
             273.72,397.06 267.57,406.59 270.75,412.00
             270.75,412.00 290.99,434.00 290.99,434.00
             290.99,434.00 300.04,444.00 300.04,444.00
             300.04,444.00 330.00,474.00 330.00,474.00
             330.00,474.00 345.00,488.83 345.00,488.83
             346.87,490.51 350.53,493.76 353.00,494.19
             356.14,494.74 367.09,487.55 371.00,485.87
             381.72,481.28 393.36,478.87 405.00,479.00
             451.50,479.54 487.53,519.28 487.00,565.00
             486.93,571.10 485.12,581.18 483.25,587.00
             482.39,589.72 479.54,595.27 480.40,597.83
             481.31,600.55 489.41,605.93 492.00,607.87
             492.00,607.87 525.00,632.37 525.00,632.37
             568.05,664.22 616.16,697.38 662.00,725.00
             662.00,725.00 711.00,754.15 711.00,754.15
             714.69,756.26 726.30,763.79 730.00,763.04
             733.21,762.40 741.19,755.00 744.00,752.58
             755.25,742.87 767.55,729.47 777.08,718.00
             811.41,676.65 833.75,624.13 841.13,571.00
             841.13,571.00 844.00,540.00 844.00,540.00
             844.00,540.00 844.00,516.00 844.00,516.00
             844.00,516.00 843.09,506.00 843.09,506.00
             841.95,489.66 839.96,475.02 836.42,459.00
             811.41,345.72 719.39,256.87 606.00,234.20
             593.57,231.72 568.26,228.02 556.00,228.14
             556.00,228.14 527.00,228.14 527.00,228.14 Z
           M 255.00,452.00
           C 252.29,456.01 251.93,460.33 251.00,465.00
             251.00,465.00 246.72,492.00 246.72,492.00
             241.87,531.41 245.69,573.84 256.58,612.00
             269.83,658.43 297.11,705.65 331.01,740.00
             339.24,748.33 352.53,759.26 362.00,766.37
             406.44,799.76 454.74,819.25 510.00,825.16
             510.00,825.16 530.00,827.00 530.00,827.00
             530.00,827.00 559.00,827.00 559.00,827.00
             559.00,827.00 571.00,826.09 571.00,826.09
             598.72,824.06 625.98,817.78 652.00,808.05
             661.58,804.47 686.09,794.28 693.00,788.00
             648.18,764.02 574.17,714.72 532.00,684.58
             532.00,684.58 484.00,649.63 484.00,649.63
             484.00,649.63 458.00,630.44 458.00,630.44
             452.96,627.99 449.20,632.05 445.00,634.55
             438.52,638.40 429.37,641.93 422.00,643.58
             395.25,649.56 368.23,642.67 348.00,623.91
             331.39,608.51 320.92,586.76 320.04,564.00
             319.78,557.38 322.00,544.40 324.03,538.00
             325.17,534.40 328.37,528.52 326.89,525.00
             325.65,522.09 321.38,518.99 319.00,516.83
             319.00,516.83 305.00,503.00 305.00,503.00
             305.00,503.00 273.04,471.00 273.04,471.00
             273.04,471.00 255.00,452.00 255.00,452.00 Z
           M 872.00,650.00
           C 872.00,650.00 860.69,675.00 860.69,675.00
             853.51,690.64 844.87,705.69 835.33,720.00
             825.89,734.17 814.51,749.55 802.91,762.00
             795.88,769.55 782.32,781.57 777.00,789.00
             777.00,789.00 823.00,810.42 823.00,810.42
             854.38,823.81 888.68,836.06 922.00,843.42
             933.04,845.86 971.20,852.88 981.00,853.00
             985.99,853.05 996.87,853.43 1000.62,849.49
             1003.18,846.39 1001.82,837.75 1000.62,834.00
             997.27,820.18 990.75,807.35 983.72,795.00
             964.31,760.93 932.31,720.00 906.73,690.00
             906.73,690.00 872.00,650.00 872.00,650.00 Z" />
  </svg>
);